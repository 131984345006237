import CloudyBackground from "~/marketing/components/CloudyBackground.js";
import Footer from "~/marketing/components/Footer.js";
import TopNav from "~/marketing/components/TopNav.js";
import Customers from "./Customers.js";
import Features from "./Features/index.js";
import Hero from "./Hero.js";
import HowItWorks from "./HowItWorks/index.js";
import Security from "./Security.js";
import Testimonials from "./Testimonials.js";
import WindyChats from "./WindyChats/index.js";

const GoldenEye = () => {
  return (
    <div>
      <CloudyBackground />
      <TopNav />
      <Hero />
      <WindyChats />
      <Features />
      <Testimonials />
      <HowItWorks />
      <Security />
      <Customers />
      <Footer />
    </div>
  );
};

export default GoldenEye;
