import { motion } from "framer-motion";

import { cn, Markdown } from "@wind/ui";

import WindmillLogoIcon from "~/components/WindmillLogoIcon.js";
import type { ExampleChatData, ExampleChatMessage } from "~/marketing/data/example-chats.js";
import { ChatMessageSender } from "~/marketing/data/example-chats.js";
import { generateImageUrl } from "~/utils/generateImageUrl.js";

interface Props {
  chat: ExampleChatData;
  message: ExampleChatMessage;
  index: number;
}

const ChatMessage = ({ chat, message, index }: Props) => {
  switch (message.sender) {
    case ChatMessageSender.USER:
    case ChatMessageSender.ASSISTANT:
      return (
        <motion.div
          initial={{
            opacity: 0,
            y: 20,
            filter: "blur(20px)",
          }}
          animate={{
            opacity: 1,
            y: 0,
            filter: "blur(0px)",
            transition: {
              delay: index * 0.2,
              duration: 0.5,
            },
          }}
          className="flex flex-row justify-start items-start py-2 gap-4"
        >
          <div
            className={cn(
              "size-12 lg:size-16 flex-none rounded-[0.75rem] lg:rounded-[1rem] overflow-hidden flex items-center justify-center bg-surface-muted border",
              {
                "border-blue-200": message.sender === ChatMessageSender.ASSISTANT,
              }
            )}
          >
            {message.sender === ChatMessageSender.ASSISTANT && (
              <WindmillLogoIcon className="fill-highlight w-14" />
            )}
            {message.sender === ChatMessageSender.USER && (
              <div
                className="bg-cover bg-center size-16"
                style={{
                  backgroundImage: `url("${generateImageUrl(chat.userAvatarUrl, {
                    width: 200,
                    height: 200,
                  })}")`,
                }}
              />
            )}
          </div>
          <Markdown className="text-sm lg:text-base">{message.message}</Markdown>
        </motion.div>
      );
    case ChatMessageSender.SYSTEM:
      return (
        <div className="flex flex-row justify-center">
          <div className="px-2 border rounded-full bg-surface-muted text-xs uppercase tracking-wider font-medium text-secondary">
            {message.message}
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default ChatMessage;
