import { motion, useInView } from "framer-motion";

import { cn, useIsMobile } from "@wind/ui";

import Icon from "~/icons/components/Icon.js";
import type { FeaturedIntegration } from "~/marketing/data/integrations.js";
import { FEATURED_INTEGRATIONS } from "~/marketing/data/integrations.js";

interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
}

const IntegrationCard = ({
  integration,
  shouldAnimate,
  delay,
  className,
}: {
  integration: FeaturedIntegration;
  shouldAnimate: boolean;
  delay: number;
  className?: string;
}) => {
  const isMobile = useIsMobile();

  return (
    <motion.div
      className={cn(
        "p-8 bg-gradient-to-b flex items-center justify-center from-white/60 to-white/20 rounded-[1.5rem] border border-white/70",
        className
      )}
      animate={{ opacity: shouldAnimate ? 1 : 0 }}
      transition={{ delay }}
    >
      <Icon icon={{ integrationId: integration.integrationId }} size={isMobile ? "lg" : "2xl"} />
    </motion.div>
  );
};

const GRID_ROW_CLASS_NAME = "grid grid-cols-4 gap-4 xl:gap-8 lg:grid-cols-8";

const IntegrationsSection = ({ containerRef }: Props) => {
  const shouldAnimate = useInView(containerRef, {
    once: true,
    amount: 0.5,
    margin: "0px 0px -10% 0px",
  });

  return (
    <div
      className="mt-8 relative flex flex-col gap-4 xl:gap-8 max-w-screen-xl mx-auto px-4 pb-12"
      ref={containerRef}
    >
      <div className="border-dashed border-1 border-white absolute top-1/2 bottom-0 w-[1px] left-1/2 -translate-x-1/2" />
      <div className={GRID_ROW_CLASS_NAME}>
        {FEATURED_INTEGRATIONS.slice(0, 8).map((integration) => (
          <IntegrationCard
            key={integration.integrationId}
            integration={integration}
            shouldAnimate={shouldAnimate}
            delay={Math.random() + 0.3}
          />
        ))}
      </div>
      <div className={GRID_ROW_CLASS_NAME}>
        {FEATURED_INTEGRATIONS.slice(8, 10).map((integration) => (
          <IntegrationCard
            className="hidden lg:block"
            key={integration.integrationId}
            integration={integration}
            shouldAnimate={shouldAnimate}
            delay={Math.random() + 0.3}
          />
        ))}
        <motion.div
          className="col-span-4 p-4 bg-gradient-to-b from-white/60 to-white/20 rounded-[1.5rem] border border-white/70 flex items-center justify-center shadow-lg backdrop-blur-lg"
          animate={{ opacity: shouldAnimate ? 1 : 0 }}
        >
          <div className="p-4 bg-white/50 rounded-[0.75rem]">
            <div className="text-center text-balance text-lg lg:text-xl font-medium">
              Windy integrates with your existing productivity tools to get a baseline understanding
              of what&apos;s going on
            </div>
          </div>
        </motion.div>
        {FEATURED_INTEGRATIONS.slice(10, 12).map((integration) => (
          <IntegrationCard
            className="hidden lg:block"
            key={integration.integrationId}
            integration={integration}
            shouldAnimate={shouldAnimate}
            delay={Math.random() + 0.3}
          />
        ))}
      </div>

      <div className={GRID_ROW_CLASS_NAME}>
        {FEATURED_INTEGRATIONS.slice(12, 20).map((integration) => (
          <IntegrationCard
            key={integration.integrationId}
            integration={integration}
            shouldAnimate={shouldAnimate}
            delay={Math.random() + 0.3}
          />
        ))}
      </div>
    </div>
  );
};

export default IntegrationsSection;
