import { useRef } from "react";

import { cn, Header } from "@wind/ui";

import SectionHeader from "~/marketing/components/SectionHeader.js";
import { EXAMPLE_CHATS } from "~/marketing/data/example-chats.js";
import { NavSectionId } from "~/marketing/data/sections.js";
import useSlideshow from "~/marketing/hooks/useSlideshow.js";
import SectionContainer from "../SectionContainer.js";
import ChatContainer from "./ChatContainer.js";
import ChatDescription from "./ChatDescription.js";

const ROTATION_INTERVAL_MS = 7000;

interface Props {
  className?: string;
}

const WindyChats = ({ className }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const slideshow = useSlideshow({
    itemCount: EXAMPLE_CHATS.length,
    duration: ROTATION_INTERVAL_MS,
    containerRef,
    autoPlay: false,
  });

  const selectedChat = EXAMPLE_CHATS[slideshow.currentIndex];

  return (
    <SectionContainer id={NavSectionId.WindyChats} className={cn(className)} ref={containerRef}>
      <SectionHeader>Meet Windy</SectionHeader>
      <Header level={3} className="text-center text-balance max-w-screen-md mx-auto">
        Windy handles the busywork, so your team can focus on what matters
      </Header>
      <div className="flex flex-col-reverse lg:flex-row gap-6 lg:gap-24 py-4">
        <div className="lg:w-[500px] flex-none">
          <ChatContainer chat={selectedChat} currentIndex={slideshow.currentIndex} />
        </div>
        <ChatDescription chat={selectedChat} slideshow={slideshow} />
      </div>
    </SectionContainer>
  );
};

export default WindyChats;
