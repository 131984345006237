import { Header } from "@wind/ui";

import { SOC_LOGO_URL } from "~/components/marketing/images.js";
import { NavSectionId } from "~/marketing/data/sections.js";
import {
  SECURITY_COMPLIANCE,
  SECURITY_DESCRIPTION,
  SECURITY_TITLE,
} from "~/marketing/data/security.js";
import SectionContainer from "./SectionContainer.js";

const Security = () => (
  <SectionContainer id={NavSectionId.Security}>
    <div className="px-4 py-16 flex flex-col items-center max-w-screen-lg mx-auto">
      <div className="flex flex-col items-center text-center">
        <div className="bg-gradient-to-b from-white/70 to-white/20 rounded-full p-4 mb-8">
          <img src={SOC_LOGO_URL} alt="AICPA SOC" className="w-48 h-48" />
        </div>
        <Header level={1} className="text-center text-balance mb-4">
          {SECURITY_TITLE}
        </Header>
        <p className="text-xl text-center max-w-3xl mb-2 text-balance">{SECURITY_DESCRIPTION}</p>
        <p className="text-xl text-balance">{SECURITY_COMPLIANCE}</p>
      </div>
    </div>
  </SectionContainer>
);

export default Security;
