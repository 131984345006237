export interface FeaturedIntegration {
  integrationId: string;
  name: string;
}

export const FEATURED_INTEGRATIONS: FeaturedIntegration[] = [
  {
    integrationId: "slack",
    name: "Slack",
  },
  {
    integrationId: "googleWorkspace",
    name: "Google Workspace",
  },
  {
    integrationId: "github",
    name: "GitHub",
  },
  {
    integrationId: "hubspot",
    name: "HubSpot",
  },
  {
    integrationId: "jira",
    name: "Jira",
  },
  {
    integrationId: "figma",
    name: "Figma",
  },
  {
    integrationId: "roam",
    name: "Roam",
  },
  {
    integrationId: "salesforce",
    name: "Salesforce",
  },
  {
    integrationId: "zoom",
    name: "Zoom",
  },
  {
    integrationId: "asana",
    name: "Asana",
  },
  {
    integrationId: "zendesk",
    name: "Zendesk",
  },
  {
    integrationId: "notion",
    name: "Notion",
  },
  {
    integrationId: "linear",
    name: "Linear",
  },
  {
    integrationId: "justWorks",
    name: "JustWorks",
  },
  {
    integrationId: "front",
    name: "Front",
  },
  {
    integrationId: "bambooHR",
    name: "BambooHR",
  },
  {
    integrationId: "airtable",
    name: "Airtable",
  },
  {
    integrationId: "gusto",
    name: "Gusto",
  },
  {
    integrationId: "paylocity",
    name: "Paylocity",
  },
  {
    integrationId: "adp-workforce-now",
    name: "ADP",
  },
  {
    integrationId: "workday",
    name: "Workday",
  },
  {
    integrationId: "gong",
    name: "Gong",
  },
  {
    integrationId: "loom",
    name: "Loom",
  },
  {
    integrationId: "hibob",
    name: "HiBob",
  },
];
