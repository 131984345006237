import { cn } from "@wind/ui";

import { type Feature } from "~/marketing/data/features.js";
import FeatureInner from "./FeatureInner.js";

interface Props {
  feature: Feature;
  isSelected: boolean;
  onClick: () => void;
}

const FeatureButton = ({ feature, isSelected, onClick }: Props) => (
  <button
    key={feature.title}
    onClick={onClick}
    className="text-left p-4 rounded-[1rem] w-full flex flex-col transition-all bg-gradient-to-b from-white/60 via-white/50 to-white/40 backdrop-blur-lg relative group"
  >
    <div
      className={cn(
        "absolute inset-0 opacity-0 transition-all bg-white pointer-events-none -z-10 rounded-[1rem]",
        {
          "opacity-100": isSelected,
          "group-hover:opacity-50": !isSelected,
        }
      )}
    />
    <FeatureInner feature={feature} isSelected={isSelected} />
  </button>
);

export default FeatureButton;
