import { motion, useInView } from "framer-motion";
import { useRef } from "react";

import { Markdown, useIsMobile } from "@wind/ui";

import { ChatMessageSender, EXAMPLE_CHATS } from "~/marketing/data/example-chats.js";

const EXAMPLE_CHAT_OPENERS = EXAMPLE_CHATS.filter(
  (chat) => chat.messages[0].sender === ChatMessageSender.ASSISTANT
).map((chat) => chat.messages[0]);

interface ChatsSectionProps {
  containerRef: React.RefObject<HTMLDivElement>;
}
const EXPANDED_COORDINATES = [
  [20, 5, 8],
  [50, 8, 3],
  [97, 5, -3],

  [12, 25, 3],
  [43, 75, 2],
  [15, 50, -10],
  [85, 35, -4],
  [90, 60, 4],
  [50, 70, -2],
  [50, 10, -3],
  [75, 45, -3],
  [40, 60, 2],
  [60, 60, -2],
];

const ChatCard = ({
  message,
  left,
  top,
  rotation,
}: {
  message: string;
  left: number;
  top: number;
  rotation: number;
}) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(chatContainerRef, { once: true });
  const isMobile = useIsMobile();

  return (
    <motion.div
      ref={chatContainerRef}
      className="px-4 py-4 max-h-[8rem] backdrop-blur-md bg-white/50 rounded-[1rem] border border-white/50 absolute flex gap-4 w-[400px] -translate-x-1/2 -translate-y-1/2 overflow-hidden"
      style={{
        left: `${left}%`,
        top: `${top}%`,
        x: "-50%",
        rotate: rotation,
      }}
      animate={{
        y: isInView ? 0 : 30,
        opacity: isInView ? (isMobile ? 0.25 : 1) : 0,
        scale: isInView ? 1 : 0.5,
      }}
      transition={{
        delay: 0.4 + Math.random() * 0.2,
      }}
    >
      <div style={{ maskImage: "linear-gradient(to bottom, black 66%, transparent 100%)" }}>
        <Markdown>{message}</Markdown>
      </div>
    </motion.div>
  );
};

const ChatsSection = ({ containerRef }: ChatsSectionProps) => {
  return (
    <div
      className="relative p-12 flex flex-col justify-start overflow-x-hidden h-[600px]"
      ref={containerRef}
    >
      <div className="left-0 top-0 right-0 bottom-0 absolute max-w-screen-2xl mx-auto">
        {EXAMPLE_CHAT_OPENERS.map((chat, index) =>
          EXPANDED_COORDINATES[index] ? (
            <ChatCard
              key={index}
              message={chat.message}
              left={EXPANDED_COORDINATES[index][0]}
              top={EXPANDED_COORDINATES[index][1]}
              rotation={EXPANDED_COORDINATES[index][2]}
            />
          ) : null
        )}
      </div>
      <div className="border-dashed border-1 border-white absolute top-0 bottom-0 w-[1px] left-1/2 -translate-x-1/2" />
      <div className="flex flex-col items-center">
        <div className="p-4 mt-36 z-0 bg-gradient-to-b from-white/60 to-white/20 rounded-[1.5rem] border border-white/70 shadow-lg backdrop-blur-lg">
          <div className="bg-white rounded-[0.75rem] text-center text-balance w-full max-w-sm px-3 py-4 text-lg lg:text-xl font-medium">
            Windy chats with your team to collect additional feedback and fill in the gaps
          </div>
        </div>
      </div>
    </div>
  );
};
ChatsSection.displayName = "ChatsSection";

export default ChatsSection;
