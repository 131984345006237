import { cn } from "@wind/ui";

import { type Feature } from "~/marketing/data/features.js";
import type { SlideshowResult } from "~/marketing/hooks/useSlideshow.js";

interface Props {
  selectedFeature: Feature;
  slideshow: SlideshowResult;
  className?: string;
}

const VideoSlideshow = ({ selectedFeature, className }: Props) => {
  return (
    <div className={cn("w-full p-4 bg-white/50 rounded-[1rem]", className)}>
      <div
        key={selectedFeature.title}
        className="overflow-hidden shadow-lg border aspect-[16/9] bg-gradient-to-b from-white/70 to-white/20 rounded-[0.5rem]"
      >
        <video
          src={selectedFeature.video}
          autoPlay
          playsInline
          loop
          muted
          className="w-full h-full object-cover rounded-md aspect-[4/3]"
        />
      </div>
    </div>
  );
};

export default VideoSlideshow;
