import type { MotionValue } from "framer-motion";
import { animate, useMotionValue, useTransform } from "framer-motion";
import { useEffect, useRef, useState } from "react";

interface SlideshowOptions {
  itemCount: number;
  duration: number | number[];
  visibilityThreshold?: number;
  containerRef: React.RefObject<HTMLDivElement>;
  autoPlay?: boolean;
}

export interface SlideshowResult {
  currentIndex: number;
  progress: MotionValue<number>;
  handleNextItem: () => void;
  handlePreviousItem: () => void;
  goToIndex: (index: number) => void;
  itemCount: number;
  autoPlay: boolean;
}

const useSlideshow = ({
  itemCount,
  duration,
  visibilityThreshold = 0.6,
  containerRef,
  autoPlay = true,
}: SlideshowOptions): SlideshowResult => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const animationRef = useRef<ReturnType<typeof animate> | null>(null);

  const progressMotion = useMotionValue(0);
  const progress = useTransform(progressMotion, (value) => Math.min(value, 100));

  const getCurrentDuration = (index: number): number => {
    if (Array.isArray(duration)) {
      return duration[index] || duration[0];
    }
    return duration;
  };

  const resetTimer = () => {
    if (animationRef.current) {
      animationRef.current.stop();
    }
    progressMotion.set(0);

    if (isVisible) {
      startTimer();
    }
  };

  const goToIndex = (index: number) => {
    if (index < 0 || index >= itemCount) {
      return;
    }
    resetTimer();
    setCurrentIndex(index);
  };

  const handleNextItem = () => {
    resetTimer();
    setCurrentIndex((prevIndex) => (prevIndex === itemCount - 1 ? 0 : prevIndex + 1));
  };

  const handlePreviousItem = () => {
    resetTimer();
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? itemCount - 1 : prevIndex - 1));
  };

  const startTimer = () => {
    const currentDuration = getCurrentDuration(currentIndex);

    animationRef.current = animate(progressMotion, 100, {
      duration: currentDuration / 1000, // Convert ms to seconds for framer-motion
      ease: "linear",
      onComplete: handleNextItem,
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting && entry.intersectionRatio >= visibilityThreshold);
      },
      { threshold: [0, 0.2, 0.4, 0.6, 0.8, 1] }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [visibilityThreshold]);

  useEffect(() => {
    if (isVisible && autoPlay) {
      startTimer();
    } else if (animationRef.current) {
      animationRef.current.pause();
    }

    return () => {
      if (animationRef.current) {
        animationRef.current.stop();
      }
    };
  }, [isVisible, currentIndex, autoPlay]);

  return {
    currentIndex,
    progress,
    handleNextItem,
    handlePreviousItem,
    goToIndex,
    itemCount,
    autoPlay,
  };
};

export default useSlideshow;
