import { useRef } from "react";

import SectionHeader from "~/marketing/components/SectionHeader.js";
import { FEATURES } from "~/marketing/data/features.js";
import { NavSectionId } from "~/marketing/data/sections.js";
import useSlideshow from "~/marketing/hooks/useSlideshow.js";
import SectionContainer from "../SectionContainer.js";
import FeatureButton from "./FeatureButton.js";
import FeatureInner from "./FeatureInner.js";
import VideoSlideshow from "./VideoSlideshow.js";

interface Props {
  className?: string;
}

const Features = ({ className }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const slideshow = useSlideshow({
    itemCount: FEATURES.length,
    duration: FEATURES.map((f) => f.durationMs),
    containerRef,
    autoPlay: false,
  });

  const selectedFeature = FEATURES[slideshow.currentIndex];

  return (
    <SectionContainer id={NavSectionId.Features} className={className} ref={containerRef}>
      <SectionHeader>A modern way to manage</SectionHeader>
      <div className="flex flex-col gap-4 lg:hidden">
        {FEATURES.map((feature, index) => (
          <div
            key={index}
            className="rounded-[1rem] p-4 bg-gradient-to-b from-white/60 via-white/50 to-white/40 backdrop-blur-lg"
          >
            <FeatureInner feature={feature} />
          </div>
        ))}
      </div>
      <div className="mt-4 hidden lg:block">
        <div className="flex gap-4">
          {FEATURES.map((feature, index) => (
            <FeatureButton
              key={feature.title}
              feature={feature}
              isSelected={slideshow.currentIndex === index}
              onClick={() => slideshow.goToIndex(index)}
            />
          ))}
        </div>
        <VideoSlideshow className="mt-6" selectedFeature={selectedFeature} slideshow={slideshow} />
      </div>
    </SectionContainer>
  );
};

export default Features;
