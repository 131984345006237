import { cn } from "@wind/ui";

import Icon from "~/icons/components/Icon.js";
import { type Feature } from "~/marketing/data/features.js";

interface Props {
  feature: Feature;
  className?: string;
  isSelected?: boolean;
}

const FeatureInner = ({ feature, className, isSelected }: Props) => (
  <div className={cn("flex flex-col items-start gap-1", className)}>
    <Icon
      icon={{ icon: feature.icon }}
      size="md"
      className={cn({ "text-highlight transition-all": isSelected })}
    />
    <h3 className="font-medium mt-2">{feature.title}</h3>
    <p className="text-sm text-tertiary">{feature.description}</p>
  </div>
);

export default FeatureInner;
