import { Link } from "@remix-run/react";
import { AnimatePresence, motion } from "framer-motion";

import { cn, FlexibleSpacer, Marquee } from "@wind/ui";

import WaitlistForm from "~/marketing/components/WaitlistForm.js";
import { TAGLINE, TAGLINE_SUBTITLE } from "~/marketing/data/copy.js";
import { CUSTOMER_META } from "~/marketing/data/customers.js";
import { NavSectionId } from "~/marketing/data/sections.js";
import { ChevronRightIcon } from "~/utils/icons.js";
import { generateImageUrl } from "../../utils/generateImageUrl.js";
import SectionContainer from "./SectionContainer.js";

interface Props {
  className?: string;
}

const Hero = ({ className }: Props) => {
  return (
    <SectionContainer
      id={NavSectionId.Hero}
      className={cn("pt-0", className)}
      containerClassName="pt-0"
    >
      <div className="min-h-[calc(100vh-20rem)] lg:min-h-[calc(100vh-10rem)] flex flex-col gap-4 items-center">
        <FlexibleSpacer />
        <div className="flex flex-col gap-12 items-center pt-8 pb-16">
          <Link
            to="/about-us"
            className="text-center font-medium text-secondary border rounded-full pl-4 pr-2 py-1 backdrop-blur-sm border-white bg-gradient-to-b from-white/70 to-white/30 flex gap-1 items-center group transition-all hover:bg-white"
          >
            We are frustrated operators{" "}
            <ChevronRightIcon className="group-hover:translate-x-1 transition" />
          </Link>
          <div className="flex flex-col gap-4">
            <h1 className="text-center text-4xl md:text-5xl lg:text-7xl font-medium font-serif max-w-3xl text-balance mx-auto">
              {TAGLINE}
            </h1>
            <h3 className="text-center text-xl md:text-2xl font-medium text-primary text-balance max-w-3xl mx-auto">
              {TAGLINE_SUBTITLE}
            </h3>
          </div>
          <WaitlistForm />
        </div>
        <FlexibleSpacer />
        <div className="px-4 pb-12 w-full hidden lg:block">
          <Marquee
            className="w-full max-w-screen-lg mx-auto"
            style={{
              maskImage:
                "linear-gradient(to right, transparent, black 30%, black 70%, transparent 100%)",
              WebkitMaskImage:
                "linear-gradient(to right, transparent, black 30%, black 70%, transparent 100%)",
            }}
          >
            {Object.values(CUSTOMER_META)
              .filter((c) => c.logo)
              .map((c) => {
                const { name, logo } = c;

                return (
                  <img
                    key={name}
                    alt={name}
                    src={generateImageUrl(logo!, {
                      width: 1200,
                      height: 600,
                      fit: "contain",
                    })}
                    className="object-contain lg:w-16 xl:w-20 grayscale hover:grayscale-0 transition-all mx-4"
                  />
                );
              })}
          </Marquee>
        </div>
      </div>
    </SectionContainer>
  );
};

export default Hero;
