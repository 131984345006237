export enum ChatMessageSender {
  USER = "user",
  ASSISTANT = "assistant",
  SYSTEM = "system",
}

export interface ExampleChatMessage {
  message: string;
  sender: ChatMessageSender;
}

export interface ExampleChatData {
  title: string;
  description: string;
  userAvatarUrl: string;
  messages: ExampleChatMessage[];
}

export const EXAMPLE_CHATS: ExampleChatData[] = [
  {
    title: "Realtime Feedback",
    description:
      "Streamline feedback collection by prompting teammates for timely, structured input on projects and performance. ",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/104.jpg",
    messages: [
      {
        message:
          "Hey **@Alex**! I saw you and Jordan collaborated on the Onboarding Revamp Project. How was it working with Jordan, any areas where Jordan excelled?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "Jordan has been super proactive in keeping the project on track. Jordan’s attention to detail on the UX improvements has really helped refine the user experience.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "That’s great to hear!  I’ll note Jordan’s strong **proactiveness** and **UX contributions**. Is there anything you think Jordan could improve on?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "The only thing I’d mention is that sometimes Jordan takes on too many tasks at once, which can slow down approvals. Maybe a bit more delegation could help speed things up.",
        sender: ChatMessageSender.USER,
      },
    ],
  },
  {
    title: "Weekly Accomplishments",
    description:
      "Automatically compile individual and team accomplishments, giving visibility into contributions without manual tracking.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/120.jpg",
    messages: [
      {
        message:
          "Hey **@Jess**!  Happy Friday! Here are some of the top accomplishments we found for you this week, please let me know if you want to make any changes: \n\n• **Finalized user onboarding flow** and resolved feedback from the design team.\n\n• **Tested and debugged API integrations**, fixing two major issues ahead of schedule.\n\n• **Prepped for next sprint planning**, outlining key priorities for the team.\nJust reply here if you want to make any changes.",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "Looks great—just add that I also reviewed analytics for onboarding drop-off rates.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Got it! I’ll add your **analytics review** to the summary. Let me know if anything else comes up! ",
        sender: ChatMessageSender.ASSISTANT,
      },
    ],
  },
  {
    title: "1:1 Preparation",
    description:
      "Automate 1:1 meeting prep by collecting agenda items, tracking previous discussions, and surfacing relevant updates.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/108.jpg",
    messages: [
      {
        message:
          "Can you help me prep for my 1:1 with Mark later today? I want to make sure I have the latest updates and any relevant feedback.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Of course, Hannah! Here’s a quick summary to help you get ready:\n\n**Recent Work & Accomplishments:**\n\n• Mark led the final integration of the new reporting dashboard—delivered on time and well received by the team.\n\n• Took initiative in resolving a critical API bug last week, reducing downtime by 30%.\n\n**Recent Feedback:**\n\n•Peers appreciate Mark’s problem-solving skills and ability to stay calm under pressure.",
        sender: ChatMessageSender.ASSISTANT,
      },
    ],
  },
  {
    title: "Process Improvement",
    description:
      "Identify inefficiencies in your operational model. Collect feedback on meetings, offsites, and systems to ensure you always understand the perspectives of the team.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/114.jpg",
    messages: [
      {
        message:
          "Hey **@Taylor**! I’m gathering feedback on our last **All Hands meeting**—would love to hear your thoughts! What worked well, and is there anything you think we could improve?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "Overall, I thought it was informative, especially the product roadmap update. But the Q&A felt a bit rushed, and I think we could use more time for team shoutouts.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Good to know! I’ll note that the product update was valuable, but we might need to extend the Q&A.",
        sender: ChatMessageSender.ASSISTANT,
      },
    ],
  },
  {
    title: "Team Performance Analysis",
    description:
      "Track and understand performance across your team. Identify trends, feedback, and metrics to keep your team on track. ",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/111.jpg",
    messages: [
      {
        message:
          "Can you pull a summary of our **sales performance over the last three months**? I’d love to see key stats and any trends we should be paying attention to.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Sure thing, Hope! Here’s your **Sales Performance Summary (Last 3 Months)**:\n\n**Key Stats**:\n\n• **Total Customer Meetings**: 124\n\n• **Total Revenue**: $2.8M (Up 12% from the previous quarter)\n\n• **New Deals Closed**: 78 (Down 5% from last quarter)\n\n**Areas for Improvement**:\n\n• **Lead conversion drop-off** – MQL-to-SQL conversion dipped 6%, suggesting lead quality may need reassessment.",
        sender: ChatMessageSender.ASSISTANT,
      },
    ],
  },
  {
    title: "Meeting Feedback",
    description:
      "Collect post-meeting insights to measure effectiveness, engagement, and value. Leaders get data-driven recommendations on which meetings to improve, consolidate, or eliminate.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/122.jpg",
    messages: [
      {
        message:
          "Hey Henry! 👋 I’m gathering feedback on the **Sales Kickoff**—what worked well, and anything we could improve?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "The product deep dive was great, and the customer success stories were super motivating. But the agenda felt packed—some sessions ran over.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Got it! ✅ You liked the **content** but felt the **pace was too tight**. Would adding buffer time or fewer sessions make it more effective?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "Probably both—cutting a couple of lower-priority sessions and adding Q&A time would help.",
        sender: ChatMessageSender.USER,
      },
    ],
  },
  {
    title: "Flag Blockers",
    description:
      "**Windy** proactively identifies blockers by surfacing issues from team conversations, project updates, and individual check-ins.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/100.jpg",
    messages: [
      {
        message:
          "Hey **@Alex**! Just checking in—do you have any blockers slowing you down this week? Let me know, and I’ll help surface them to the right people.",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "Yeah, actually—I’m stuck waiting on final approval for the new feature launch. It’s been sitting in review for three days, and I haven’t gotten any updates.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Got it! I see that Sarah is responsible for the final sign-off. Would you like me to nudge her and ask for an update?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message: "That would be great!",
        sender: ChatMessageSender.USER,
      },
    ],
  },
  {
    title: "Goal Planning",
    description:
      "**Windy** aligns team and individual goals with company priorities by tracking progress in real-time and providing data-driven insights.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/112.jpg",
    messages: [
      {
        message:
          "Hey **@Jordan**! Hope your week is off to a great start! Do you have any key goals or priorities for this week? ",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "Yeah, I have a few. The biggest ones are finalizing the Q2 marketing strategy, cleaning up our lead pipeline, and prepping for the product launch next week.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Nice! I’ve logged those as your top goals for the week. Do you want me to remind you about any of these mid-week?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message: "A mid-week check-in would be awesome, especially on the product launch prep.",
        sender: ChatMessageSender.USER,
      },
    ],
  },
  {
    title: "Top Five Updates",
    description:
      "**Windy** automatically compiles and prioritizes key updates across projects, ensuring managers and teams stay informed without excessive meetings.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/121.jpg",
    messages: [
      {
        message:
          "Hey **@Taylor**! Quick check-in—what are the top five things on your mind this week? I can help track them and surface relevant updates as you go.",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "Let’s see… 1) Finalizing the Q1 performance report, 2) Prepping for the leadership offsite, 3) Syncing with Product on the new feature rollout, 4) Reviewing hiring plans for next quarter, and 5) Following up with the finance team on budget approvals.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Got it! I’ve logged those as your top priorities. Want me to set any reminders or pull updates from your team on these?",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message: "Just remind me on Friday so I can make sure these are completed.",
        sender: ChatMessageSender.USER,
      },
    ],
  },
  {
    title: "Engineering Sprint Retro",
    description:
      "Automate sprint retrospectives by gathering performance metrics, tracking team sentiment, and surfacing improvement areas.",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/129.jpg",
    messages: [
      {
        message:
          "Can you pull together a sprint retro for the engineering team? I’d love to see key stats and a summary of what worked well and what didn’t.",
        sender: ChatMessageSender.USER,
      },
      {
        message:
          "Based on team communications and tracking over the past two weeks, I've identified several recurring themes for your retrospective:\n\n**Top Issues to Address:**\n\n1. **CI/CD Pipeline Delays** - 7 engineers mentioned waiting on builds, with average delays of 27 minutes\n2. **API Documentation Gaps** - 5 mentions of missing or outdated docs when integrating with the payment service\n\n**Positive Highlights:**\n\n- The new PR template has reduced review cycles by 24%",
        sender: ChatMessageSender.ASSISTANT,
      },
    ],
  },
  {
    title: "Career Development",
    description:
      "Track employee growth, skill development, and career goals, providing personalized recommendations for coaching and mentorship. ",
    userAvatarUrl: "https://mighty.tools/mockmind-api/content/human/126.jpg",
    messages: [
      {
        message:
          "Hey **@Chris**! I've gathered feedback from your colleagues—here's a summary of your strengths and areas for improvement.\n\n**Strengths**:\n\n• **Clear Communication** – Teammates mentioned you explain ideas concisely and make complex topics easy to understand.\n\n• **Proactive Problem-Solving** – You identify blockers early and take initiative to resolve them before escalation.\n\n**Areas for Improvement**:\n\n• **Prioritization** – Feedback suggests you take on too many tasks at once, stretching bandwidth and impacting focus.\n\n• **Delegation** – Some teammates feel you could offload routine tasks to free up time for higher-priority work.",
        sender: ChatMessageSender.ASSISTANT,
      },
      {
        message:
          "This is really helpful. Can you remind me to chat about prioritization during my next 1:1 with my manager?",
        sender: ChatMessageSender.USER,
      },
      {
        message: "Of course! I’ll add it to your next 1:1 agenda as a discussion topic. ",
        sender: ChatMessageSender.ASSISTANT,
      },
    ],
  },
];
