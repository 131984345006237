import { forwardRef } from "react";

import { cn } from "@wind/ui";

import type { NavSectionId } from "~/marketing/data/sections.js";

interface Props {
  children: React.ReactNode;
  className?: string;
  id: NavSectionId;
  fullWidth?: boolean;
  containerClassName?: string;
}

const SectionContainer = forwardRef<HTMLDivElement, Props>(
  ({ children, className, id, fullWidth, containerClassName }, ref) => {
    return (
      <div className={cn("pt-6 md:pt-24", className)} id={id} ref={ref}>
        <div
          className={cn("flex flex-col gap-4 mx-auto py-6 px-4", containerClassName, {
            "max-w-screen-xl": !fullWidth,
          })}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default SectionContainer;
