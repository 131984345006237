interface Props {
  children: React.ReactNode;
}

const SectionHeader = ({ children }: Props) => (
  <h2 className="text-center max-w-2xl leading-10 mx-auto sm:text-3xl px-4 line-h text-3xl font-medium tracking-tight md:text-4xl font-serif text-balance">
    {children}
  </h2>
);

export default SectionHeader;
