import { motion, useInView, useScroll, useTransform } from "framer-motion";

import type { DeepResearchProgressView } from "@wind/deep-research/models";

import DeepResearchProgressCard from "~/deep-research/components/DeepResearchProgressCard/index.js";
import useDeepDiveProgress from "./useDeepDiveProgress.js";

interface Props {
  containerRef: React.RefObject<HTMLDivElement>;
}

const EXAMPLE_PROGRESS: DeepResearchProgressView = {
  id: "01956d35-2483-7fdb-9491-9328e9f1ee90",
  status: "COMPLETED",
  startedAt: "2025-03-06T20:45:57.108+00:00",
  completedAt: "2025-03-06T20:47:00.268+00:00",
  steps: [
    {
      id: "01956d35-3431-7262-b361-31c8ca6e0b8a",
      createdAt: "2025-03-06T20:45:58.185+00:00",
      updatedAt: "2025-03-06T20:45:58.185+00:00",
      startedAt: "2025-03-06T20:45:58.185+00:00",
      completedAt: "2025-03-06T20:46:07.237+00:00",
      label: "Thinking",
      runId: "01956d35-2483-7fdb-9491-9328e9f1ee90",
      logs: [
        {
          id: "01956d35-3435-79aa-80cf-d48582207a25",
          createdAt: "2025-03-06T20:45:58.185+00:00",
          stepId: "01956d35-3431-7262-b361-31c8ca6e0b8a",
          integrationId: null,
          standardIconType: "LIGHT_BULB",
          message:
            "Coming up with a plan to answer the question: What has my team been focusing on?",
        },
        {
          id: "01956d35-5787-772f-b51d-ecb7331f8604",
          createdAt: "2025-03-06T20:46:07.237+00:00",
          stepId: "01956d35-3431-7262-b361-31c8ca6e0b8a",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Planned Team Focus Areas - Researching 1 report",
        },
      ],
    },
    {
      id: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
      createdAt: "2025-03-06T20:46:08.359+00:00",
      updatedAt: "2025-03-06T20:46:08.359+00:00",
      startedAt: "2025-03-06T20:46:08.359+00:00",
      completedAt: "2025-03-06T20:46:21.996+00:00",
      label: "Researching",
      runId: "01956d35-2483-7fdb-9491-9328e9f1ee90",
      logs: [
        {
          id: "01956d35-5be9-76f2-be25-e950dfcf692f",
          createdAt: "2025-03-06T20:46:08.359+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "TELESCOPE",
          message: "Researching 6 employees using 3 researchers from 2024-12-06 to 2025-03-06",
        },
        {
          id: "01956d35-5bec-792b-b492-54c4aafe515f",
          createdAt: "2025-03-06T20:46:08.364+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "ACCOMPLISHMENTS",
          message: "Running Accomplishments Researcher",
        },
        {
          id: "01956d35-5c01-7d1f-89ef-6915ff02bbdf",
          createdAt: "2025-03-06T20:46:08.385+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "ACCOMPLISHMENTS",
          message: "16 accomplishments found over the period of 2024-12-06 to 2025-03-06.",
        },
        {
          id: "01956d35-5c05-7f81-baee-9592e1aee5b4",
          createdAt: "2025-03-06T20:46:08.389+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Running Statistician Researcher",
        },
        {
          id: "01956d35-907e-79d3-8e37-c27333532189",
          createdAt: "2025-03-06T20:46:21.817+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 1: Team Meeting Time Distribution",
        },
        {
          id: "01956d35-90b4-75a2-b114-352a4d7066ca",
          createdAt: "2025-03-06T20:46:21.876+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 2: Meeting Types Breakdown",
        },
        {
          id: "01956d35-90c1-766f-a6b4-1356a4d22e58",
          createdAt: "2025-03-06T20:46:21.889+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 3: Code Activity by Team Member",
        },
        {
          id: "01956d35-90d1-73e7-8461-b06f187cd462",
          createdAt: "2025-03-06T20:46:21.905+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 4: GitHub Issue Activity",
        },
        {
          id: "01956d35-90e0-7ca0-87cd-08e59e21e717",
          createdAt: "2025-03-06T20:46:21.92+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 5: Linear Issue Activity",
        },
        {
          id: "01956d35-90ea-7954-87f8-e6af6a6243cb",
          createdAt: "2025-03-06T20:46:21.93+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 6: Documentation Activity",
        },
        {
          id: "01956d35-90f4-75f8-aa49-e811139b0239",
          createdAt: "2025-03-06T20:46:21.94+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 7: Team Communication",
        },
        {
          id: "01956d35-90fc-7083-8590-4848567e199e",
          createdAt: "2025-03-06T20:46:21.948+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 8: Code Commit Trends",
        },
        {
          id: "01956d35-9103-7f1f-a952-cd7ebdcf2236",
          createdAt: "2025-03-06T20:46:21.956+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 9: Issue Completion Trends",
        },
        {
          id: "01956d35-910f-71a6-ae57-957ba02c0bb9",
          createdAt: "2025-03-06T20:46:21.966+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "Generating exhibit 10: Team Meeting Time Trends",
        },
        {
          id: "01956d35-9118-7791-8cc6-12a782d1f32b",
          createdAt: "2025-03-06T20:46:21.976+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "CHOOSE_STATS",
          message: "10 exhibits generated",
        },
        {
          id: "01956d35-911a-7e83-afe3-90d035acad92",
          createdAt: "2025-03-06T20:46:21.979+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "FEEDBACK_RECEIVED",
          message: "Running Feedback Received Researcher",
        },
        {
          id: "01956d35-912a-741d-a733-a6b898fbe24c",
          createdAt: "2025-03-06T20:46:21.994+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "FEEDBACK_RECEIVED",
          message: "0 piece of feedbacks found over the period of 2024-12-06 to 2025-03-06.",
        },
        {
          id: "01956d35-912c-79c7-b11d-f63358636b05",
          createdAt: "2025-03-06T20:46:21.996+00:00",
          stepId: "01956d35-5be8-72f2-bf09-ce436f12a0a5",
          integrationId: null,
          standardIconType: "TELESCOPE",
          message: "Finished researching",
        },
      ],
    },
    {
      id: "01956d35-9130-79cb-9a50-2d41a4d7ee96",
      createdAt: "2025-03-06T20:46:22+00:00",
      updatedAt: "2025-03-06T20:46:22+00:00",
      startedAt: "2025-03-06T20:46:22+00:00",
      completedAt: "2025-03-06T20:46:52.144+00:00",
      label: "Writing",
      runId: "01956d35-2483-7fdb-9491-9328e9f1ee90",
      logs: [
        {
          id: "01956d35-9132-7021-ab1b-b0a2f1bdbb58",
          createdAt: "2025-03-06T20:46:22+00:00",
          stepId: "01956d35-9130-79cb-9a50-2d41a4d7ee96",
          integrationId: null,
          standardIconType: "PENCIL_BOLT",
          message: "Writing report based on research",
        },
        {
          id: "01956d36-06f2-705a-95d4-5c39749a68bc",
          createdAt: "2025-03-06T20:46:52.144+00:00",
          stepId: "01956d35-9130-79cb-9a50-2d41a4d7ee96",
          integrationId: null,
          standardIconType: "PENCIL_BOLT",
          message: "Finished writing",
        },
      ],
    },
    {
      id: "01956d36-0b27-7f48-92b4-43354e633ea4",
      createdAt: "2025-03-06T20:46:53.223+00:00",
      updatedAt: "2025-03-06T20:46:53.223+00:00",
      startedAt: "2025-03-06T20:46:53.223+00:00",
      completedAt: "2025-03-06T20:46:59.174+00:00",
      label: "Synthesizing",
      runId: "01956d35-2483-7fdb-9491-9328e9f1ee90",
      logs: [
        {
          id: "01956d36-0b29-7960-b67d-396b21138148",
          createdAt: "2025-03-06T20:46:53.223+00:00",
          stepId: "01956d36-0b27-7f48-92b4-43354e633ea4",
          integrationId: null,
          standardIconType: "AI",
          message: "Synthesizing answer based on reports",
        },
        {
          id: "01956d36-2267-7cb8-bad5-d5fa50524c8f",
          createdAt: "2025-03-06T20:46:59.174+00:00",
          stepId: "01956d36-0b27-7f48-92b4-43354e633ea4",
          integrationId: null,
          standardIconType: "AI",
          message: "Done! Generated answer to users question.",
        },
      ],
    },
  ],
};

const ConclusionSection = ({ containerRef }: Props) => {
  return (
    <div className="py-12 relative flex flex-col items-center gap-8" ref={containerRef}>
      <div className="border-dashed border-1 border-white absolute top-0 bottom-1/2 w-[1px] left-1/2 -translate-x-1/2" />
      <div className="p-4 -mt-8 z-0 bg-gradient-to-b from-white/60 to-white/20 rounded-[1.5rem] border border-white/70 shadow-lg backdrop-blur-lg">
        <div className="bg-white/70 rounded-[0.75rem] text-center text-balance w-full max-w-sm px-3 py-4 text-lg lg:text-xl font-medium">
          Windy synthesizes all this information so you can get answers in seconds
        </div>
      </div>

      <div
        ref={containerRef}
        className="text-lg lg:text-xl w-full max-w-screen-lg p-4 backdrop-blur-md bg-gradient-to-b from-white/70 to-white/50 border border-white rounded-[1rem]"
      >
        <video
          src="https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/videos/deep-dive.mp4"
          autoPlay
          playsInline
          loop
          muted
          className="w-full h-full object-cover rounded-md aspect-[4/3]"
        />
      </div>
    </div>
  );
};

export default ConclusionSection;
