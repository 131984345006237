import { AnimatePresence } from "framer-motion";

import WindyStickerIcon from "~/components/WindyStickerIcon.js";
import type { ExampleChatData } from "~/marketing/data/example-chats.js";
import ChatMessage from "./ChatMessage.js";

interface Props {
  chat: ExampleChatData;
  currentIndex: number;
}

const ChatContainer = ({ chat, currentIndex }: Props) => {
  return (
    <div className="relative">
      <div className="relative rounded-[2rem] aspect-[9/18] h-[80vh] lg:h-auto lg:aspect-[9/12] w-full z-10 pointer-events-none overflow-hidden max-w-xl mx-auto">
        <div className="absolute inset-0 bg-[#22a7ff]/10 -z-10 blur-lg rounded-[2rem] -rotate-12" />
        <div className="absolute inset-0 bg-[#2d57b2]/10 -z-10 blur-lg rounded-[2rem] -rotate-6" />
        <div className="rounded-[2rem] p-4 lg:p-8 aspect-[9/18] h-[80vh] lg:h-auto lg:aspect-[9/12] border border-white/80 bg-gradient-to-br from-white via-white/80 to-white/20 backdrop-blur-md w-full z-10 shadow-blue-200 shadow-lg">
          <div
            className="flex flex-col gap-4 overflow-hidden absolute inset-0 px-4 lg:px-8 py-4 lg:py-6"
            key={currentIndex}
          >
            <AnimatePresence mode="sync">
              {chat.messages.map((message, index) => (
                <ChatMessage
                  key={`${currentIndex}-${index}`}
                  chat={chat}
                  message={message}
                  index={index}
                />
              ))}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <WindyStickerIcon className="hidden xl:block w-36 absolute left-0 bottom-0 -translate-x-1/2 translate-y-1/4 rotate-12 z-10" />
    </div>
  );
};

export default ChatContainer;
