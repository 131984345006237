import { StandardIconType } from "@wind/common-interfaces/icons";
import { cn, FlexibleSpacer, Markdown } from "@wind/ui";

import Icon from "~/icons/components/Icon.js";
import SectionHeader from "~/marketing/components/SectionHeader.jsx";
import { CUSTOMER_META } from "~/marketing/data/customers.js";
import { NavSectionId } from "~/marketing/data/sections.js";
import type { UserTestimonial } from "~/marketing/data/testimonials.js";
import {
  FEATURED_TESTIMONIALS,
  PRIMARY_TESTIMONIALS,
  SECONDARY_TESTIMONIALS,
  TERTIARY_TESTIMONIALS,
  TESTIMONIAL_META,
} from "~/marketing/data/testimonials.js";
import { generateImageUrl } from "~/utils/generateImageUrl.js";
import SectionContainer from "./SectionContainer.js";

interface Props {
  className?: string;
}

const Testimonial = ({
  testimonial,
  featured,
}: {
  testimonial: UserTestimonial;
  featured?: boolean;
}) => (
  <div
    className={cn(
      "flex flex-col gap-2 rounded-[0.75rem] overflow-hidden p-4 relative group bg-gradient-to-b from-white/70 to-white/40",
      {
        "gap-4": featured,
      }
    )}
  >
    <div className="absolute inset-0 group-hover:opacity-50 bg-white transition-all opacity-0 -z-10"></div>
    <Icon
      icon={{
        imageUrl: testimonial.author.imageUrl,
        fallbackIcon: StandardIconType.USER_CIRCLE,
      }}
      size={featured ? "2xl" : "xl"}
    />

    <Markdown
      className={cn("mt-2 font-base", {
        "text-lg mt-4": featured,
      })}
    >
      {testimonial.body}
    </Markdown>

    <div className="flex">
      <div>
        <div className={cn("font-medium text-lg", { "text-xl": featured })}>
          {testimonial.author.name}
        </div>
        {testimonial.author.title && (
          <div className={cn("text-secondary", { "text-lg": featured })}>
            {testimonial.author.title}
            {testimonial.company ? ` at ${CUSTOMER_META[testimonial.company].name}` : ""}
          </div>
        )}
      </div>
      <FlexibleSpacer />
      {testimonial.company && CUSTOMER_META[testimonial.company].logo && featured && (
        <img
          alt={CUSTOMER_META[testimonial.company].name}
          src={generateImageUrl(CUSTOMER_META[testimonial.company].logo!, {
            width: 1200,
            height: 600,
            fit: "contain",
          })}
          className="object-contain grayscale transition-all w-16"
        />
      )}
    </div>
  </div>
);

const Testimonials = ({ className }: Props) => (
  <SectionContainer
    id={NavSectionId.Testimonials}
    className={className}
    containerClassName="px-0 lg:px-4"
  >
    <SectionHeader>Industry leaders are revolutionizing the way they manage</SectionHeader>
    <div className="flex flex-col gap-4 mt-4">
      <div
        className="lg:hidden overflow-x-auto pb-4 scrollbar-hide"
        style={{ scrollSnapType: "x mandatory" }}
      >
        <div className="flex flex-row gap-2 min-w-max pl-4">
          {FEATURED_TESTIMONIALS.map((testimonial, i) => (
            <div
              key={i}
              className="w-[calc(100vw-3rem)] flex-shrink-0"
              style={{ scrollSnapAlign: "center" }}
            >
              <Testimonial testimonial={TESTIMONIAL_META[testimonial]} />
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="mt-4 hidden lg:flex">
      <div className="w-[30%] pr-1 flex flex-col gap-3">
        {SECONDARY_TESTIMONIALS.map((testimonial, i) => (
          <Testimonial key={i} testimonial={TESTIMONIAL_META[testimonial]} />
        ))}
      </div>
      <div className="w-[40%] px-2 flex flex-col gap-3">
        {PRIMARY_TESTIMONIALS.map((testimonial, i) => (
          <Testimonial key={i} testimonial={TESTIMONIAL_META[testimonial]} featured />
        ))}
      </div>
      <div className="w-[30%] pl-1 flex flex-col gap-3">
        {TERTIARY_TESTIMONIALS.map((testimonial, i) => (
          <Testimonial key={i} testimonial={TESTIMONIAL_META[testimonial]} />
        ))}
      </div>
    </div>
  </SectionContainer>
);

export default Testimonials;
