import SectionHeader from "~/marketing/components/SectionHeader.js";
import { CUSTOMER_META, FEATURED_CUSTOMERS } from "~/marketing/data/customers.js";
import { NavSectionId } from "~/marketing/data/sections.js";
import { generateImageUrl } from "~/utils/generateImageUrl.js";
import SectionContainer from "./SectionContainer.js";

interface Props {
  className?: string;
}

const Customers = ({ className }: Props) => (
  <SectionContainer id={NavSectionId.Customers} className={className}>
    <SectionHeader>Empowering forward-thinking teams across the globe</SectionHeader>
    <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mt-4">
      {FEATURED_CUSTOMERS.map((c) => CUSTOMER_META[c])
        .filter((c) => c.logo)
        .map((c) => {
          const { name, logo } = c;

          return (
            <div
              className="p-8 aspect-[16/7] flex items-center justify-center overflow-hidden rounded-[1rem] relative bg-gradient-to-b from-white/50 to-white/40 group"
              key={name}
            >
              <div className="absolute inset-0 bg-white -z-10 opacity-0 group-hover:opacity-50 transition-all"></div>
              <img
                alt={name}
                src={generateImageUrl(logo!, {
                  width: 1200,
                  height: 600,
                  fit: "contain",
                })}
                className="object-contain w-full grayscale hover:grayscale-0 transition-all max-w-32"
              />
            </div>
          );
        })}
    </div>
  </SectionContainer>
);

export default Customers;
