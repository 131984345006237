import { Markdown } from "@wind/ui";

import type { ExampleChatData } from "~/marketing/data/example-chats.js";
import type { SlideshowResult } from "~/marketing/hooks/useSlideshow.js";
import { ChevronLeftIcon, ChevronRightIcon } from "~/utils/icons.js";

interface Props {
  chat: ExampleChatData;
  slideshow: SlideshowResult;
}

const ChatSlideshowButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: React.ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      className="relative bg-primary text-white bg-gradient-to-b from-white/80 to-white/20 border-white hover:bg-white border shadow-blue-200 shadow-lg rounded-full size-12 md:size-20 flex items-center justify-center transition-all"
    >
      {children}
    </button>
  );
};

const ChatDescription = ({ chat, slideshow }: Props) => (
  <div className="flex flex-col gap-4 justify-center w-full">
    <div className="hidden lg:block lg:text-lg font-medium text-secondary">{chat.title}</div>
    <Markdown className="text-xl lg:text-3xl leading-normal text-center lg:text-left">
      {chat.description}
    </Markdown>
    <div className="flex flex-row gap-4 justify-between lg:justify-start">
      <ChatSlideshowButton onClick={slideshow.handlePreviousItem}>
        <ChevronLeftIcon className="text-3xl text-blue-300" />
      </ChatSlideshowButton>
      <ChatSlideshowButton onClick={slideshow.handleNextItem}>
        <ChevronRightIcon className="text-3xl text-blue-300" />
      </ChatSlideshowButton>
    </div>
  </div>
);

export default ChatDescription;
