import { AnimatePresence, motion, useInView, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import { cn, Header } from "@wind/ui";

import PublicIntegrationsApi from "~/api/PublicIntegrationsApi.js";
import { ChatMessageSender, EXAMPLE_CHATS } from "~/marketing/data/example-chats.js";
import { NavSectionId } from "~/marketing/data/sections.js";
import SectionContainer from "../SectionContainer.js";
import ChatsSection from "./ChatsSection.js";
import ConclusionSection from "./ConclusionSection.js";
import IntegrationsSection from "./IntegrationsSection.js";

// Filter chats where the first message is from the assistant
const WINDY_CHAT_OPENERS = EXAMPLE_CHATS.filter(
  (c) => c.messages[0].sender === ChatMessageSender.ASSISTANT
);

interface Props {
  className?: string;
}

const HowItWorks = ({ className }: Props) => {
  const integrationsSectionRef = useRef<HTMLDivElement>(null);
  const chatsSectionRef = useRef<HTMLDivElement>(null);
  const conclusionSectionRef = useRef<HTMLDivElement>(null);

  return (
    <SectionContainer
      id={NavSectionId.HowItWorks}
      className={cn(className)}
      fullWidth
      containerClassName="p-0"
    >
      <Header className="text-center" level={1}>
        How it works
      </Header>
      <div className="relative flex flex-col py-12">
        <IntegrationsSection containerRef={integrationsSectionRef} />
        <ChatsSection containerRef={chatsSectionRef} />
        <ConclusionSection containerRef={conclusionSectionRef} />
      </div>
    </SectionContainer>
  );
};

export default HowItWorks;
